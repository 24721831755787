<template>
  <div class="container mt-5 mb-md-4 py-5">
    <breadcrumb class="mb-3 mb-md-4 pt-md-3" :crumbs="crumbs"></breadcrumb>

    <!-- Page content -->
    <div class="row justify-content-center pb-sm-2 pt-3">
      <div class="col-lg-6">
        <h1 class="h2 text-center pb-4 mb-3">Forgot Your Password?</h1>

        <div class="bg-light rounded-3 py-4 px-md-4">

          <form action="post" @submit.prevent="requestReset">
            <!-- Email -->
            <div class="mb-4">
              <label class="form-label" for="email">Email <span class="text-danger">*</span></label>
              <input v-model="form.email" class="form-control form-control-lg" type="email" id="email"
                     placeholder="Enter your email address" required>
            </div>
            <!-- / Email -->


            <input type="submit" class="d-none"/>

            <div class="actions-wrapper">
              <div class="left">
                <a :class="{'disabled opacity-50': isLoading}"
                   @click.prevent="requestReset" class="btn btn-primary btn-lg rounded-pill ms-sm-auto" href="#">
                  <spinner v-if="isLoading" class="spinner-border-sm me-2"></spinner>
                  <i v-else class="fi-lock fs-sm me-2"></i>
                  Reset Password</a>
              </div>
              <div class="right">
                <a href="/login">Or login here</a>
              </div>
            </div>
          </form>

        </div>
      </div>
    </div>
    <!-- / Page content -->
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import Spinner from "../components/Spinner";
import axios from 'axios';
import Forms from "../mixins/Forms";

import {useToast} from "vue-toastification";
const toast = useToast();

export default {
  components: {Breadcrumb, Spinner},
  mixins: [Forms],
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'Forgotten Password',
          active: true
        }
      ],
      form: {
        email: ''
      },
      isLoading: false
    }
  },
  methods: {
    requestReset() {
      let vm = this;
      vm.isLoading = true;
      axios.post(window.API_BASE + '/forgot-password', {
        email: vm.form.email
      }).then(() => {
        vm.isLoading = false;
        toast.success('Password reset email on the way!');
        vm.form.email = '';
      }).catch(e => {
        console.log(e);
        vm.isLoading = false;
        vm.setAndNotifyErrors(e, 'Error requesting password reset');
      });
    }
  },
  computed: {},
  setup() {

  },
}
</script>


<style scoped lang="scss">
.actions-wrapper {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>